import { WrapPageElementBrowserArgs } from 'gatsby';
import * as React from 'react';
import ReactQueryWrapper from 'src/components/ReactQueryWrapper';
import LaunchDarklyFlags from './components/launch_darkly/LaunchDarklyFlags';
import OneTrustButton from './components/oneTrust/OneTrustButton';

// eslint-disable-next-line react/function-component-definition
export const WrapRootWithProvider = ({ element }: { element: React.ReactNode }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <ReactQueryWrapper>{element}</ReactQueryWrapper>;
};

export const WrapPageWithProvider: (args: WrapPageElementBrowserArgs) => React.ReactNode = ({
  element,
  ...rest
}) => {
  const node = rest.props?.data?.node as any;
  const hideFooter = node?.behaviors?.page_layout?.elements_to_hide?.footer;

  return (
    <>
      {hideFooter && <OneTrustButton />}
      <LaunchDarklyFlags />
      {element}
    </>
  );
};
